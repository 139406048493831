.productFeatures {
  .container {
    height: 800px;
  }

  .icon-box {
    margin-bottom: 14px;
    .icon {
      max-height: $productFeatures-img-height;
    }
  }
  .title {
    font-family: 'NotoKrM';
    margin-top: 0;
    margin-bottom: 10px;
    font-size: $productFeatures-title-font-size;
    color: $productFeatures-title-color;
  }
  .desc-box {
    .dot {
      width: 7px;
      height: 7px;
      margin-top: 12px;
      background-color: $nerd-gray-900;
      display: inline-block;
      vertical-align: top;
    }
    .desc {
      font-family: 'NotoKrR';
      margin-left: 10px;
      margin-top: 0;
      margin-bottom: 0;
      opacity: 0.8;
      font-size: $productFeatures-desc-font-size;
      color: $productFeatures-desc-color;
      display: inline-block;
    }
  }

  .feature-box + .feature-box {
    padding-left: 10%;
  }
  .feature-box + .feature-box + .feature-box {
    padding-left: 10%;
  }
  .bottom-feat {
    margin-top: 40px;
  }
}
