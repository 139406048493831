$contactus-title-font-size: 32px;
$contactus-title-color: $nerd-gray-900;

$contactus-desc-font-size: 20px;
$contactus-desc-color: $nerd-gray-900;

$contactus-desc-box-width: 269px;
$contactus-desc-box-height: 342px;
$contactus-desc-box-border-radius: 8px;
$contactus-desc-box-color: #eff2f5;

$contactus-phone-number-color: $nerd-gray-900;
$contactus-phone-number-font-size: 12px;

$contactus-icon-height: 18px;

$contactus-form-title-font-size: 12px;
$contactus-form-title-color: $nerd-gray-900;

$contactus-input-height: 40px;
$contactus-input-width: 400px;
$contactus-input-border-radius: 8px;
$contactus-input-border: solid 1px #e4e4eb;
$contactus-input-font-size: 14px;

$contactus-text-height: 102px;

$contactus-height: 720px;
