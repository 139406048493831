.benefit {
  background-image: linear-gradient(to bottom, $white, #f9fafb);
  .bottombackground {
    width: 100%;
    background-color: #f9fafb;
    @include media-breakpoint-down(sm) {
      margin-top: 10px;
      width: 100%;
    }
  }
  .container {
    height: 600px;
    @include media-breakpoint-down(md) {
      height: 1100px;
    }
    @include media-breakpoint-down(sm) {
      height: 890px;
    }
    .top {
      margin-bottom: 60px;
      @include media-breakpoint-down(md) {
        margin-bottom: -10px;
      }
    }
  }
  .mobile {
    display: none !important;
    @include media-breakpoint-down(sm) {
      display: block !important;
    }
  }
  .logo-box-mobile {
    display: none !important;
    @include media-breakpoint-down(sm) {
      display: flex !important;
    }
  }
  .desktop {
    @include media-breakpoint-down(sm) {
      display: none !important;
    }
  }
  .section-heading {
    font-family: 'NotoKrL';
    font-size: 28px;
    color: $nerd-gray-900;
    margin-top: 0;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }
  .contrast-text {
    font-family: 'NotoKrB';
    color: $nerd-blue-800;
    font-weight: bold;
  }
  .company-top {
    justify-content: center;
  }
  .company-bottom {
    justify-content: center;
  }
  .logo-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-down(md) {
      margin-top: 50px;
    }
    .title {
      font-family: 'NotoKrM';
      font-size: 20px;
      font-weight: 500;
      color: $nerd-gray-900;
      margin-bottom: 10px;
      margin-top: 20px;
      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }
    .desc {
      font-family: 'NotoKrR';
      font-size: 14px;
      font-weight: normal;
      color: $nerd-gray-900;
      @include media-breakpoint-down(sm) {
        font-size: 12px;
      }
    }
  }
  .logo-box-inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      width: 120px;
    }
  }
  .logo1 {
    height: 100px;
    @include media-breakpoint-down(sm) {
      height: 80px;
    }
  }
}
