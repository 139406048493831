.hoxy {
  background-image: linear-gradient(to bottom, $white, #f5f5f5) !important;

  .bottombackground {
    width: 108%;
    background-color: $white;
  }

  .container {
    position: relative;
    display: table;
    margin: 0 auto;
    .col-md-12 {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .container {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    height: 800px;
    @include media-breakpoint-down(xs) {
      height: 510px;
    }
    .row {
      // height: 100%;
      align-content: center;
      margin-left: 0;
      margin-right: 0;
    }
    .bottom-row {
      margin-top: 40px;
    }
    .title {
      font-family: 'NotoKrB';
      color: $hoxy-title-color;
      font-size: $hoxy-title-font-size;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 10px;
      @include media-breakpoint-down(xs) {
        font-size: 18px;
        margin-bottom: 6px;
      }
    }
    .desc1 {
      font-family: 'NotoKrR';
      color: $hoxy-desc1-color;
      font-size: $hoxy-desc1-font-size;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 0;
      @include media-breakpoint-down(xs) {
        font-size: 22px;
        display: none;
      }
    }
    .desc1-mobile {
      display: none;
      font-family: 'NotoKrR';
      color: $hoxy-desc1-color;
      font-size: $hoxy-desc1-font-size;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 0;
      @include media-breakpoint-down(xs) {
        display: block;
        font-size: 22px;
      }
    }
    .desc2 {
      font-family: 'NotoKrR';
      color: $hoxy-desc2-color;
      font-size: $hoxy-desc2-font-size;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 0;
      opacity: 0.8;
      @include media-breakpoint-down(xs) {
        font-size: 18px;
      }
    }
    .desc3 {
      font-family: 'NotoKrR';
      color: $hoxy-desc3-color;
      font-size: $hoxy-desc3-font-size;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 0;
      opacity: 0.6;
      @include media-breakpoint-down(xs) {
        font-size: 16px;
      }
    }
    .desc4 {
      font-family: 'NotoKrR';
      color: $hoxy-desc4-color;
      font-size: $hoxy-desc4-font-size;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 0;
      opacity: 0.4;
      @include media-breakpoint-down(xs) {
        font-size: 14px;
      }
    }
    .desc5 {
      font-family: 'NotoKrR';
      color: $hoxy-desc5-color;
      font-size: $hoxy-desc5-font-size;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 0;
      opacity: 0.25;
    }
  }

  .logo-box {
    text-align: center;
    margin-top: 72.9px;
    position: relative;
    @include media-breakpoint-down(xs) {
      margin-top: 40px;
    }
    @-webkit-keyframes mover {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-10px);
      }
    }
    @keyframes mover {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-10px);
      }
    }

    .logo {
      max-height: 215.2px;
      @include media-breakpoint-down(xs) {
        max-height: 123.2px;
      }
    }
    .logo-bubble {
      position: absolute;
      left: 33%;
      top: -33%;
      max-height: 89.3px;
      -webkit-animation: mover 1s infinite alternate;
      animation: mover 1s infinite alternate;
      @include media-breakpoint-down(xs) {
        max-height: 51.2px;
      }
    }
  }

  .gslogo-box {
    margin-top: 20px;
    span {
      margin-left: 10px;
      font-size: 16px;
      color: $nerd-gray-900;
      opacity: 0.8;
      font-weight: normal;
    }
  }
  .gslogo {
    max-height: 40px;
  }
}
// .fixed-top-top {
//   position: sticky !important;
//   top: 900px;
//   // height: 800px;
//   // z-index: 0 !important;
// }
