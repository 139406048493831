$footer_height: 110px;
$footer_background_color: black;

$footer_font_size: 14px;
$footer_font_weight: normal;
$footer_font_color: $nerd-gray-600;
$footer_link_font_color: $nerd-gray-300;
$footer_link_font_weight: 500;

$footer_split_height: 13px;
$footer_split_border_color: $nerd-gray-600;

$footer_logo_height: 28px;
