.search-engine {
  .first {
    margin-top: 0 !important;
  }
  .package {
    height: $searchEngine-img-height;
    flex-direction: column;
    justify-content: center;
    margin-top: 100px;
    margin-left: 0;
    margin-right: 0;
    @include media-breakpoint-down(xs) {
      flex-direction: row;
      margin-top: 90px;
    }

    .col-md-6 {
      // flex-basis: auto;
    }
    .col-12 {
      // flex-wrap: wrap;
      // display: flex;
      @include media-breakpoint-down(xs) {
        display: flex;
        justify-content: center;
        padding: 0 !important;
      }
    }

    .section-heading {
      margin-bottom: 10px;
      position: relative;

      h2 {
        font-family: 'NotoKrB';
        margin-top: 0;
        margin-bottom: 0; //($padding-default / 2);
        font-weight: bold;
        color: $nerd-blue-800;
        font-size: 28px;
        @include media-breakpoint-down(sm) {
          font-size: 24px;
        }
        @include media-breakpoint-down(xs) {
          margin-top: 20px;
          margin-bottom: 5px;
          font-size: 18px;
        }
      }
    }
    .section-desc {
      margin-bottom: 10px;
      position: relative;
      h5 {
        font-family: 'NotoKrR';
        margin-top: 0;
        margin-bottom: 0;
        font-size: 20px;
        color: $nerd-gray-900;
        line-height: 1.4;
        letter-spacing: -0.02px;
        @include media-breakpoint-down(sm) {
          font-size: 16px;
        }
        @include media-breakpoint-down(xs) {
          font-size: 14px;
        }
      }
      .desc-desktop {
        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
      .desc-mobile {
        display: none;
        @include media-breakpoint-down(xs) {
          display: block;
        }
      }
    }
    .section-detail {
      div {
        // height: 100%;
        display: flex;
        align-items: center;
        .dot {
          width: 5px;
          height: 5px;
          // margin-bottom: 5px;
          opacity: 0.5;
          background-color: $nerd-gray-900;
          display: inline-block;
          vertical-align: middle;
          &-none {
            background-color: transparent;
          }
        }
        p {
          font-family: 'NotoKrR';
          margin-left: 5px;
          margin-top: 0;
          margin-bottom: 0;
          opacity: 0.7;
          font-size: 16px;
          color: $nerd-gray-900;
          display: inline-block;
          // line-height: 1.38;
          // letter-spacing: -0.01px;
          @include media-breakpoint-down(sm) {
            font-size: 12px;
          }
          @include media-breakpoint-down(xs) {
            font-size: 12px;
          }
        }
      }
    }
  }
  .logo-box {
    .logo {
      max-height: $searchEngine-img-height;
      @include media-breakpoint-down(sm) {
        max-height: 200px;
      }
      @include media-breakpoint-down(xs) {
        max-height: 200px;
      }
    }
  }
}
