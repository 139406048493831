$companny-heading-font-size: 32px;

$companny-logo1-height: 26.7px;
$companny-logo2-height: 29.7px;
$companny-logo3-height: 23.2px;
$companny-logo4-height: 32.1px;
$companny-logo5-height: 32.7px;
$companny-logo6-height: 19px;
$companny-logo7-height: 26.1px;
$companny-logo8-height: 19px;
$companny-logo9-height: 32.5px;
$companny-logo10-height: 50.5px;
