$soft-pink: #ffa1a3;
$Secondary01: #323b45;
$Secondary02: #6d747b;
$inactive-button: #f5f5f5;
$placeholder-text: #b6b9bd;
$cancle-text: #8a8c8f;
$status-critical: #ff3b30;
$white: #ffffff;
$black: #000000;
$barney-purple: #8800aa;
$red: #ff0000;

$nerd-gray-900: #212121;
$nerd-gray-400: #bdbdbd;
$nerd-gray-500: #9e9e9e;
$nerd-gray-300: #d3d3d3;
$nerd-gray-800: #323232;
$nerd-gray-50: #fafafa;
$nerd-gray-600: #757575;
$nerd-gray-200: #eeeeee;
$nerd-gray-700: #424242;
$nerd-blue-gray: #cfd8d8;
$nerd-green-gray: #263232;
$nerd-blue-800: #0355aa;
$nerd-blue-300: #abcdef;
$nerd-blue-600: #00aadd;
$nerd-pink: #ffa1a1;
$nerd-blue-100: #d8e8f8;
$nerd-blue-50: #ddeeff;

$color-accent: $red !default;
$color-accent-inverse: #ffffff !default;
