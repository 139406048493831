$hoxy-title-color: $nerd-blue-800;
$hoxy-title-font-size: 28px;

$hoxy-desc1-color: $nerd-gray-900;
$hoxy-desc1-font-size: 32px;

$hoxy-desc2-color: $nerd-gray-900;
$hoxy-desc2-font-size: 28px;

$hoxy-desc3-color: $nerd-gray-900;
$hoxy-desc3-font-size: 24px;

$hoxy-desc4-color: $nerd-gray-900;
$hoxy-desc4-font-size: 20px;

$hoxy-desc5-color: $nerd-gray-900;
$hoxy-desc5-font-size: 16px;
