.navigation {
  &.fixed-top {
    + main .header {
      padding-top: $navbar-min-height;
    }
  }
}
.header {
  position: relative;

  .container {
    position: relative;
    display: table;
    margin: 0 auto;
    .col-md-12 {
      vertical-align: middle;
      height: 100%;
      text-align: -moz-center;
      text-align: -webkit-center;
    }
  }

  .container {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    height: 800px;
    display: table;
    @include media-breakpoint-down(xs) {
      height: 460px;
    }
    .row {
      height: 100%;
      width: 100%;
      display: table;
      // align-content: center;
      margin-left: 0;
      margin-right: 0;
      .text-center {
        display: table-cell;
        vertical-align: middle;
        .box {
          position: relative;
        }
      }
    }
    .title {
      position: relative;
      font-family: 'NotoKrR';
      color: $heading-title-font-color;
      font-size: $heading-title-font-size;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 0;
      z-index: 2;
      @include media-breakpoint-down(xs) {
        font-size: 16px;
      }
    }
    .title-bold {
      font-family: 'NotoKrM';
      position: relative;
      z-index: 2;
      color: $heading-title-bold-font-color;
      font-size: $heading-title-bold-font-size;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 0;
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
    .mobile-title-bold {
      display: none;
      font-family: 'NotoKrM';
      position: relative;
      z-index: 2;
      color: $heading-title-bold-font-color;
      font-size: 24px;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 0;
      @include media-breakpoint-down(xs) {
        display: block;
      }
    }
    .text-center {
      position: relative;
    }
    .networkImg {
      max-height: 381px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      z-index: 0;
      @include media-breakpoint-down(xs) {
        height: 160.3px;
      }
    }

    .gslogo-box {
      margin-top: 20px;
      span {
        font-family: 'NotoKrR';
        margin-left: 10px;
        font-size: 16px;
        color: $nerd-gray-900;
        opacity: 0.8;
        font-weight: normal;
      }
    }
    .gslogo {
      max-height: 40px;
    }
  }
}
