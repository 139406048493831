.navigation {
  &.fixed-top {
    + main .header {
      padding-top: $navbar-min-height;
    }
  }
}
.gs {
  position: relative;

  .container {
    position: relative;
    display: table;
    margin: 0 auto;
    .col-md-12 {
      display: table-cell;
      vertical-align: middle;
      text-align: -webkit-center;
    }
    .lottie {
      position: absolute;
      left: 0;
      top: 50px;

      @include media-breakpoint-down(xs) {
        top: 20px;
        height: 80% !important;
      }
    }
  }

  .container {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    display: table;
    height: 500px;
    @include media-breakpoint-down(xs) {
      height: 240px;
    }
    .row {
      height: 100%;
      display: table;
      width: 100%;
      // align-content: center;
      margin-left: 0;
      margin-right: 0;
      .text-center {
        width: 100%;
        display: table-cell;
        vertical-align: middle;
      }
    }
    .title {
      font-family: 'NotoKrR';
      color: $gs-title-font-color;
      font-size: $gs-title-font-size;
      font-weight: 500;
      margin-top: 20px;
      margin-bottom: 0;
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
    .title-mobile {
      display: none;
      font-family: 'NotoKrR';
      color: $gs-title-font-color;
      font-size: 18px;
      font-weight: 500;
      margin-top: 10px;
      margin-bottom: 0;
      @include media-breakpoint-down(xs) {
        display: block;
      }
    }
    @include media-breakpoint-down(xs) {
      h2:nth-child(4) {
        margin-top: 0px;
      }
    }
  }

  .logo-box {
    // padding-bottom: $heading-logo-padding-bottom;
    // float: right;
  }
  .logo {
    max-height: 67px;
    @include media-breakpoint-down(xs) {
      max-height: 42px;
    }
  }
  .gslogo-box {
    margin-top: 10px;
    @include media-breakpoint-down(xs) {
      margin-top: 3px;
    }
    span {
      font-family: 'NotoKrR';
      margin-left: 10px;
      font-size: 16px;
      color: $nerd-gray-900;
      opacity: 0.8;
      font-weight: normal;
      @include media-breakpoint-down(xs) {
        font-size: 12px;
      }
    }
  }
  .gslogo {
    max-height: 27px;
    @include media-breakpoint-down(xs) {
      max-height: 20px;
    }
  }
}
