main {
  transition: transform 0.4s ease 0s;
  overflow: hidden;
}

.navbar-nav {
  position: relative;
}

.navbar {
  @include media-breakpoint-up($grid-float-breakpoint) {
    .dropdown {
      &.mega {
        position: static;
      }
    }
  }
}

.nav-top {
  height: 80px !important;
  @include media-breakpoint-down(xs) {
    height: 56px !important;
  }
}

.navigation {
  background-color: $navbar-side-background-color;
  min-height: $navbar-min-height;
  @include media-breakpoint-down(xs) {
    padding-left: 16px;
    padding-right: 16px;
  }

  transition: all 0.3s ease 0s;

  // need to include this so I do not need to mess with the default bootstrap behavior

  .navbar-top-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    height: 56px;
    justify-content: space-between;
  }
  .navbar-toggler-button {
    background-color: transparent;
    border: none;
    padding-right: 0;
    box-shadow: none;
    @include media-breakpoint-up(md) {
      display: none !important;
    }
    .toggle-img {
      background-color: transparent;
      height: 24px;
    }
  }

  .drop-down-menu {
    position: absolute;

    width: 100%;
    height: 0px !important;
    top: 76px;
    left: 0px;
    // padding-left: 16px;
    overflow: hidden;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    background: white;
    @include media-breakpoint-up(md) {
      display: none !important;
    }
    .navbar-nav {
      max-width: 540px;
      margin-left: auto;
      margin-right: auto;
      @include media-breakpoint-down(xs) {
        padding-left: 16px;
      }
    }
  }
  .show {
    @-webkit-keyframes dropDownAni {
      0% {
        height: 0px;
      }
      100% {
        height: 170px;
      }
    }
    @keyframes dropDownAni {
      0% {
        height: 0px;
      }
      100% {
        height: 170px;
      }
    }
    -webkit-animation: dropDownAni 1s;
    animation: dropDownAni 1s;
    animation-fill-mode: forwards;
  }
  .leave {
    display: block;

    @-webkit-keyframes dropDownLeaveAni {
      0% {
        height: 170px;
      }
      100% {
        height: 0px;
      }
    }
    @keyframes dropDownLeaveAni {
      0% {
        height: 170px;
      }
      100% {
        height: 0px;
      }
    }
    -webkit-animation: dropDownLeaveAni 1s;
    animation: dropDownLeaveAni 1s;
    animation-fill-mode: forwards;
  }

  &.fixed-top {
    .navbar-collapse {
      height: 100vh;
    }
    + main .header {
      padding-top: $navbar-min-height;
    }
  }

  &.navbar-expanded {
    .icon-bar {
      &:nth-child(1) {
        transform: rotate(-225deg);
      }

      &:nth-child(2) {
        transform: translateX(-40px);
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateY(-15px) rotate(225deg);
      }
    }
  }

  .collapse {
    // transition: left 0.4s ease 0s;
    // background-color: $navbar-side-background-color;
    // width: $navbar-side-width;
    // position: fixed;
    // margin: 0;
    // top: 0;
    // bottom: 0;
    // display: block;

    // @include media-breakpoint-down($grid-float-breakpoint-max) {
    //   overflow-y: auto;
    // }
  }

  .logo {
    max-height: $navbar-logo-max-height;
    @include media-breakpoint-down(xs) {
      max-height: 20px;
    }
  }

  .sidebar-brand {
    padding: 1rem;
  }

  .nav-link {
    color: $navbar-side-text-color;
    font-family: $font-family-complementary;
    font-size: $navbar-font-size;
    font-weight: $font-weight-bold;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
    text-decoration: none;
    letter-spacing: 1px;
    padding: 1rem;
    @include media-breakpoint-down(sm) {
      font-size: 14px;
      padding: 0;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    i {
      display: inline-block;
      margin-right: 15px;
    }

    // invert colors when active
    &.active,
    &:hover {
      // @include media-breakpoint-down($grid-float-breakpoint-max) {
      //   background: $navbar-side-text-color;
      // }
      color: $navbar-fixed-text-color-hover;
    }
  }

  &.navbar-sticky {
    position: fixed !important;
    background-color: $navbar-sticky-background-color;
    left: 0;
    top: 0;
    right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.11);
    min-height: $navbar-min-height;
    z-index: 1030;
    transition: all 0.6s ease-in-out;
  }

  &,
  &.navbar-sticky {
    .btn-outline {
      @include button-outline-variant($navbar-side-text-color);
      @include hover-shadow($navbar-side-text-color);
    }

    .btn-solid {
      @include button-variant($navbar-side-text-color, $navbar-side-text-color);
      @include hover-shadow($navbar-side-text-color);
    }
  }

  &.navbar-sticky {
    .logo {
      display: none;

      &.logo-sticky {
        display: block !important;

        + .logo {
          display: none !important;
        }
      }
    }
  }

  @include media-breakpoint-up($grid-float-breakpoint) {
    background-color: $navbar-background-color;
    padding: 0;

    &:not(.fixed-top) {
      position: static;
      top: -$navbar-min-height;
    }

    &.fixed-top {
      background-color: $navbar-fixed-top-background-color;

      .navbar-collapse {
        height: auto;
      }

      .nav-link {
        color: $navbar-fixed-text-color;

        &.active,
        &:hover {
          color: $navbar-fixed-text-color-hover;
        }
      }
    }

    &.navbar-sticky {
      // position: fixed;
      background-color: $navbar-sticky-background-color;
      top: 0;

      .nav-link {
        color: $navbar-sticky-text-color;

        &.active,
        &:hover {
          //background-color: $navbar-sticky-background-color;
          color: $navbar-sticky-text-color-hover;
        }
      }
    }

    &.navbar-expanded {
      .navbar-toggler,
      .navbar-brand,
      ~ main {
        transform: translateX(0) !important;
      }
    }

    &.sidebar-left .collapse {
      left: 0;
      border-right: none;
    }

    &.sidebar-right .collapse {
      right: 0;
      border-left: none;
    }

    &.sidebar-right .navbar-brand {
      order: 0;
    }

    &.dark-link {
      .nav-link {
        color: $navbar-text-color-dark;
        opacity: 0.7;

        &.active,
        &:hover {
          opacity: 1;
          color: $navbar-text-color-dark-hover;
        }
      }

      .btn-outline {
        @include button-outline-variant($navbar-side-text-color);
      }

      .btn-solid {
        @include button-variant(
          $navbar-side-text-color,
          $navbar-side-text-color
        );
      }
    }

    .collapse {
      position: relative;
      width: auto;
      background: transparent;
      flex-basis: 180% !important;
    }

    .nav-link {
      color: $navbar-text-color;
      padding-left: 30px !important;
      padding-right: 30px !important;
      @media (max-width: 1000px) {
        padding-left: 20px !important;
        padding-right: 20px !important;
      }

      i {
        display: none;
      }

      &.active,
      &:hover {
        //background-color: $navbar-background-color;
        color: $navbar-text-color-hover;
      }
    }
    .nav-link-last {
      padding-right: 0px !important;
    }

    .dropdown {
      &.show {
        .dropdown-menu {
          //visibility: visible;
        }

        .dropdown-toggle {
          color: $navbar-fixed-text-color-hover;
        }
      }
    }

    .sidebar-brand {
      display: none;
    }

    .dropdown-menu {
      margin-top: -10px;
      border: 1px solid $border-color;
      min-width: 100%; // takes the parent width
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.075%);
    }

    &.navbar-sticky {
      .dropdown-menu {
        margin-top: -3px;
      }
    }
  }
}
