$experienceLink_text_box_font_weight: 300;
$experienceLink_text_box_font_size: 32px;
$experienceLink_text_box_color: $nerd-gray-900;
$experienceLink_contrast_text_font_weight: bold;
$experienceLink_contrast_text_color: $nerd-blue-800;

$experienceLink_top_background_color: #eff2f5;
$experienceLink_image_height: 300px;

$experienceLink_text_mini_box_font_weight: normal;
$experienceLink_text_mini_box_font_size: 14px;
$experienceLink_text_mini_box_font_color: $nerd-gray-600;

$experienceLink_button_width: 172px;
$experienceLink_button_height: 50px;
$experienceLink_button_border_radius: 8px;
$experienceLink_button_background_color: $nerd-gray-900;
$experienceLink_button_font_size: 16px;
$experienceLink_button_font_color: $white;
