.footer {
  background-color: black;
  height: 203px;
  @include media-breakpoint-down(xs) {
    height: 212px;
  }

  .container {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    font-family: 'NotoKrR';

    .row {
      align-items: center;
      margin-bottom: 4px;
      &:first-child {
        margin-bottom: 20px;
      }
    }

    .col {
      padding: 0px;

      .logo-box {
        display: flex;
        align-items: center;
        .logo {
          max-height: $footer_logo_height;
          @include media-breakpoint-down(xs) {
            max-height: 23.9px;
          }
        }
      }

      .site-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include media-breakpoint-down(md) {
          display: none;
        }
        .navbar-brand {
          margin-left: 20px;
          margin-right: 0px;

          .ic {
            height: 36px;
            @include media-breakpoint-down(md) {
              height: 28px;
            }
          }
          .bi {
            height: 22px;
            @include media-breakpoint-down(md) {
              height: 18px;
            }
          }
        }
        &-mobile {
          display: none;
          align-items: center;
          margin-top: 16px;
          height: 28px;
          @include media-breakpoint-down(md) {
            display: flex;
          }
          .navbar-brand {
            margin-left: 0px;
            margin-right: 18px;

            .ic {
              height: 28px;
            }
            .bi {
              height: 18px;
            }
          }
        }
      }

      .navbar-nav {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 20px;
        div {
          color: #9d9d9d;
          font-size: 14px;
          @include media-breakpoint-down(md) {
            font-size: 12px;
          }
        }
        .split {
          margin-left: 10px;
          margin-right: 10px;
          width: 0;
          height: $footer_split_height;
          border: solid 0.5px $footer_split_border_color;
          @include media-breakpoint-down(md) {
            height: 13px;
          }
        }
      }
    }
  }
}
