/*
[ TABLE OF CONTENTS ]

3. ANIMATIONS
4. FORMS
    3.1 - Form
    3.2 - Buttons
5. UTILITIES
    4.1 - Background
    4.2 - Border
    4.3 - Devices
    4.4 - Lists
    4.5 - Misc
    4.6 - Overlay
    4.7 - Position
    4.8 - Responsive
    4.9 - Spacing
    4.10 - Text
    4.11 - Type
6. COMPONENTS

5. GENERAL CONTENT STYLES
    5.1 - Page Loader
    5.2 - Mockup
    5.3 - Icons
6. SECTIONS
    6.1 - General Styles
    6.2 - Testimonials
    6.3 - Footer
7. PRICING
    7.1 - General styles
    7.2 - Pricing heading
8. PAGE HEADING
9. CUSTOM
*/

// @import './root/core';
@import '~bootstrap/scss/bootstrap';
@import './themes/palette.scss';
@import './variables/components.scss';
@import './variables/util.scss';
@import './variables/view.scss';
@import './variables/mixins.scss';
@import './variables/animation.scss';
@import './root/animation.scss';
@import './root/components.scss';
@import './root/view.scss';
