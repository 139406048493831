.contactus {
  height: $contactus-height;
  width: 100%;
  @include media-breakpoint-down(xs) {
    height: 740px;
  }
  .top {
    height: fit-content !important;
  }

  // position: fixed;
  .col-5 {
    padding-left: 0;
    padding-right: 20px;
  }
  .col-7 {
    padding-left: 0;
    padding-right: 0;
  }
  .col-sm-5 {
    @include media-breakpoint-down(xs) {
      display: flex;
      justify-content: center;
      padding: 0px;
    }
  }

  .container {
    padding-bottom: 0px !important;
    @include media-breakpoint-down(sm) {
      padding-top: 60px;
      max-width: 100%;
    }
  }
  .title {
    font-family: 'NotoKrB';
    font-size: $contactus-title-font-size;
    color: $contactus-title-color;
    font-weight: bold;
    @include media-breakpoint-down(xs) {
      font-size: 22px;
    }
  }

  .desc-box {
    float: right;
    width: $contactus-desc-box-width;
    height: $contactus-desc-box-height;
    border-radius: $contactus-desc-box-border-radius;
    background-color: $contactus-desc-box-color;
    padding: 25px;
    @include media-breakpoint-down(sm) {
      max-width: 90%;
      padding: 30px;
    }
    @include media-breakpoint-down(xs) {
      text-align: center;
      width: 330px;
      height: 151px;
      padding-top: 20px;
    }
    .desc {
      font-family: 'NotoKrR';
      margin-top: 20px;
      font-size: $contactus-desc-font-size;
      color: $contactus-desc-color;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 0;
      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 14px;
      }
    }
    .nerd-info {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      @include media-breakpoint-down(xs) {
        margin-top: 10px;
        justify-content: center;
      }
      .phone-number {
        font-size: $contactus-phone-number-font-size;
        color: $contactus-phone-number-color;
        @include media-breakpoint-down(xs) {
          font-size: 12px;
        }
      }
      .icon {
        height: $contactus-icon-height;
        margin-right: 5px;
        @include media-breakpoint-down(xs) {
          height: 16px;
        }
      }
    }
    .nerd-info + .nerd-info {
      margin-top: 10px;
      @include media-breakpoint-down(xs) {
        margin-top: 0px;
      }
    }
  }
  .contact-button-box {
    // flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    .circle-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 60px;
      margin-left: 60px;
      align-items: center;
    }
    .button-title-box {
      display: flex;
      justify-content: center;
    }
    .circle-select {
      @include circle-variant(60px, $nerd-blue-800);
      opacity: 1;
    }
    .circle {
      opacity: 0.3;
      @include circle-variant(60px);
      //   margin-right: 20px;
      border: solid 1px $nerd-gray-900;
      .button-img {
        height: 24px;
        // color: unquote($string: "");
        // background-color: $nerd-gray-900;
      }
    }
    .button-title {
      font-family: 'NotoKrR';
      font-size: 16px;
      margin-top: 10px;
      //   margin-right: 20px;
    }
  }
  .contact-form {
    justify-content: center;
    margin-top: 30px;
    position: relative;
    @include media-breakpoint-down(xs) {
      margin-top: 10px;
    }
    .mobile {
      display: none !important;
      @include media-breakpoint-down(xs) {
        display: flex !important;
        padding: 0;
        justify-content: center;
      }
    }
    .desktop {
      .gform{
        width: 400px;
      }
      @include media-breakpoint-down(xs) {
        display: none !important;
      }
    }
    .form-box {
      width: $contactus-input-width;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      @include media-breakpoint-down(xs) {
        width: 330px;
      }
    }
    .form-title {
      display: block;
      font-family: 'NotoKrR';
      font-weight: normal;
      font-size: $contactus-form-title-font-size;
      color: $contactus-form-title-color;
      padding-left: 5px;
      padding-top: 0 !important;
      margin-bottom: 0;
      .necessary {
        color: $nerd-gray-600;
      }
    }
    .input-form {
      // width: 500px;
      border-radius: $contactus-input-border-radius;
      height: $contactus-input-height;
      width: $contactus-input-width;
      font-size: $contactus-input-font-size;
      color: $nerd-gray-900;
      @include media-breakpoint-down(sm) {
        width: 90%;
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
      }

      // background-color: rgba($nerd-gray-900, 0.03);
    }
    ::placeholder {
      font-family: 'NotoKrR';
      font-weight: normal;
      font-size: $contactus-input-font-size;
      color: $nerd-gray-400;
    }
    .text-form {
      height: $contactus-text-height !important;
    }
    .submit-box {
      overflow: hidden;
      padding-left: 0;
      padding-right: 0;
      @include media-breakpoint-down(sm) {
        width: 90%;
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }
    .submit {
      font-family: 'NotoKrR';
      font-weight: normal;
      width: 96px;
      height: 32px;
      float: right;
      border-radius: 8px;
      border: solid 1px $white;
      background-color: $nerd-gray-200;
      font-size: 12px;
      font-weight: normal;
      color: $nerd-gray-900;
      opacity: 0.5;
      box-shadow: none !important;
    }
    .submit-on {
      background-color: $nerd-gray-900 !important;
      color: $nerd-gray-50 !important;
      opacity: 1 !important;
    }
    .toast {
      max-width: none;
      background-color: $nerd-blue-800;
      height: 68px;
      border-radius: 8px;
      color: $white;
      box-shadow: 0px 16px 24px 0 rgba(0, 0, 0, 0.16);
      // position: absolute;
      // top: 33%;
      div {
        font-family: 'NotoKrM';
      }
      .toast-desc {
        font-family: 'NotoKrR';
        font-size: 12px;
      }
    }
    .checkIcon {
      height: 20px;
      // background-color: $white;
    }
    // .col-form-label {
    //   font-family: 'NotoKrM';
    // }
    // .form-control {
    //   font-family: 'NotoKrR';
    // }
  }
  .bottom {
    margin-top: 50px;
    @include media-breakpoint-down(xs) {
      margin-top: 40px;
    }
    @-webkit-keyframes moverarrow {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(15px);
      }
    }
    @keyframes moverarrow {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(15px);
      }
    }
    .arrow {
      font-family: 'NotoKrR';
      font-size: 14px;
      color: $nerd-gray-900;
    }
    .img-box {
      display: flex;
      justify-content: center;
      -webkit-animation: moverarrow 1s infinite alternate-reverse;
      animation: moverarrow 1s infinite alternate-reverse;
      img {
        height: 20px;
      }
    }
  }
}
.contactus-fixed {
  position: fixed !important;
  width: 100%;
  left: 0;
  top: 0;
  padding-top: 80px;
}
