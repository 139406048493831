.shapes-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.shape-divider svg {
  &.shape {
    &-waves {
      height: 100px;

      @include media-breakpoint-up($grid-float-breakpoint) {
        height: 133px;
      }
    }
  }
}

.background-shape-main {
  background-color: theme-color('primary');
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 120px;
  z-index: -1;
}

.transparentize {
  opacity: 0.035;
}
