.experienceLink {
  width: 100%;
  position: absolute !important;
  top: 0;
  z-index: 300;
  .top-background {
    height: 50%;
    width: 100%;
    background-color: $experienceLink_top_background_color;
    .container {
      height: 100%;
      position: relative;
      display: table;
      padding-top: 0px;
      padding-bottom: 0px;
      .top {
        display: table-cell;
        vertical-align: bottom;
        text-align: -webkit-center;
        .image-box {
          width: 100%;
          .image {
            width: 100%;
            max-height: $experienceLink_image_height;
            background-image: linear-gradient(
              to bottom,
              rgba(250, 251, 251, 1),
              rgba(248, 250, 250, 0) 11%,
              rgba(242, 244, 246, 0.4) 74%,
              #eff2f5
            );
          }
        }
      }
    }
  }
  .bottom-background {
    height: 100%;
    width: 100%;
    background-color: #eff2f5;
    .container {
      height: 100%;
      position: relative;
      display: table;
      padding-top: 0px;
      padding-bottom: 0px;
      .bottom {
        display: table-cell;
        vertical-align: middle;
        // .box-position {
        //   position: absolute;
        //   top: -40px;
        //   left: 50%;
        //   transform: translate(-50%);
        // }
        .text-box {
          width: 100%;
          line-height: 1.4;
          letter-spacing: -0.02px;
          font-family: 'NotoKrL';
          font-weight: $experienceLink_text_box_font_weight;
          font-size: $experienceLink_text_box_font_size;
          color: $experienceLink_text_box_color;
          @include media-breakpoint-down(lg) {
            // height: 200px;
          }
          @include media-breakpoint-down(xs) {
            font-size: 20px;
          }
          // margin-top: 15px;
          margin-bottom: 0;
          .contrast-text {
            font-family: 'NotoKrB';
            color: $experienceLink_contrast_text_color;
            font-weight: $experienceLink_contrast_text_font_weight;
          }
        }
        .text-mini-box {
          font-family: 'NotoKrR';
          font-size: $experienceLink_text_mini_box_font_size;
          font-weight: $experienceLink_text_mini_box_font_weight;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: -0.01px;
          color: $experienceLink_text_mini_box_font_color;
          @include media-breakpoint-down(xs) {
            font-size: 12px;
          }
        }
        .button-center {
          display: flex;
          justify-content: center;
          margin-top: 30px;
          @include media-breakpoint-down(xs) {
            margin-top: 20px;
          }
        }
        .button-exp {
          display: table;
          width: $experienceLink_button_width;
          height: $experienceLink_button_height;
          border-radius: $experienceLink_button_border_radius;
          background-color: $experienceLink_button_background_color;
          font-family: 'NotoKrR';
          line-height: 1.38;
          letter-spacing: -0.01px;
          font-size: $experienceLink_button_font_size;
          color: $experienceLink_button_font_color;
          @include media-breakpoint-down(xs) {
            width: 120px;
            height: 40px;
            font-size: 14px;
          }
          div {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .row {
    height: 50%;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    .text-center {
      width: 100%;
    }
  }
}
