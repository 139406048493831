.company {
  // background-image: linear-gradient(to bottom, $white, #f9fafb);
  .bottombackground {
    width: 100%;
    background-color: #f9fafb;
    @include media-breakpoint-down(sm) {
      // margin-top: 10px;
      // width: 100%;
    }
  }
  .container {
    // height: 110px;
    // max-width: 1510px;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    margin-bottom: 60px;
    @include media-breakpoint-down(xs) {
      // height: 500px;
      // height: 90px;
    }
    .row {
      height: 100%;
      justify-content: center;
      
      .col {
        display: flex;
        justify-content: center;
  
        img{
          width: 100%;
          padding-bottom: 30px;

        }
        @include media-breakpoint-down(xs) {
          img{
            width: 100%;
            padding: 20px;
          }
        }
      }
    }
    
    .top {
      margin-top: 80px;
      margin-bottom: 60px;
      @include media-breakpoint-down(xs) {
        margin-top: 40px;
        margin-bottom: 30px;
      }
    }
    .company-col {
      height: 100%;
    }
  }

  .title {
    font-family: 'NotoKrR';
    color: $gs-title-font-color;
    font-size: $gs-title-font-size;
    font-weight: 500;
    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }
  .mobile {
    display: none !important;
    @include media-breakpoint-down(xs) {
      display: block !important;
    }
  }
  .logo-box-mobile {
    display: none !important;
    @include media-breakpoint-down(xs) {
      display: flex !important;
    }
  }
  .desktop {
    @include media-breakpoint-down(xs) {
      display: none !important;
    }
  }
  .section-heading {
    font-family: 'NotoKrL';
    font-size: $companny-heading-font-size;
    color: $nerd-gray-900;
    margin-top: 0;
    margin-bottom: 0;
    @include media-breakpoint-down(xs) {
      font-size: 20px;
    }
  }
  .contrast-text {
    font-family: 'NotoKrB';
    color: $nerd-blue-800;
    font-weight: bold;
  }
  .company-top {
    justify-content: center;
  }
  .company-bottom {
    justify-content: center;
  }
  .logo-box {
    width: 1396px;
    // height: 59px;
    display: flex;
    justify-content: center;
    @include media-breakpoint-down(xs) {
      // height: 50px;
      // justify-content: flex-end;
    }
  }
  .logo-box-right {
    width: 160px;
    height: 68px;
    display: flex;
    justify-content: center;
    @include media-breakpoint-down(xs) {
      height: 50px;
      justify-content: flex-start;
    }
  }
  .logo-box-inner {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(xs) {
      // width: 120px;
    }
  }
  .logo1 {
    height: 59px;
    @include media-breakpoint-down(xs) {
      // width: 93px;
      // height: 22px;
    }
  }
  .logo2 {
    height: $companny-logo2-height;
    @include media-breakpoint-down(xs) {
      width: 90px;
      height: 26px;
    }
  }
  .logo3 {
    height: $companny-logo3-height;
    @include media-breakpoint-down(xs) {
      width: 92px;
      height: 19px;
    }
  }
  .logo4 {
    height: $companny-logo4-height;
    @include media-breakpoint-down(xs) {
      width: 92px;
      height: 26px;
    }
  }
  .logo5 {
    height: $companny-logo5-height;
    @include media-breakpoint-down(xs) {
      width: 92px;
      height: 26px;
    }
  }
  .logo6 {
    height: $companny-logo6-height;
    @include media-breakpoint-down(xs) {
      width: 90px;
      height: 16px;
    }
  }
  .logo7 {
    height: $companny-logo7-height;
    @include media-breakpoint-down(xs) {
      width: 92px;
      height: 21px;
    }
  }
  .logo8 {
    height: $companny-logo8-height;
    @include media-breakpoint-down(xs) {
      width: 85px;
      height: 16px;
    }
  }
  .logo9 {
    height: $companny-logo9-height;
    @include media-breakpoint-down(xs) {
      width: 82px;
      height: 24px;
    }
  }
  .logo10 {
    height: $companny-logo10-height;
    @include media-breakpoint-down(xs) {
      width: 40px;
      height: 40px;
    }
  }

  .wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .sliding-background {
    display: inline-flex;
    height: 100%;
    width: 2796px;
    animation: slide 30s linear infinite;
    @include media-breakpoint-down(md) {
      animation: slide 40s linear infinite;
    }
  }

  @keyframes slide {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-1396px, 0, 0);
    }
  }
}
