$navbar-min-height: 80px;
$navbar-logo-max-height: 24.7px;
$navbar-font-size: 16px;
$navbar-background-color: $white; //$living-coral;
$navbar-text-color: rgba($nerd-gray-900, 0.2);
$navbar-text-color-hover: $nerd-gray-900;
$navbar-text-color-dark: $nerd-gray-700;
$navbar-text-color-dark-hover: $nerd-gray-800;

$navbar-fixed-top-background-color: transparent;
$navbar-fixed-text-color: rgba($nerd-gray-900, 0.2);
$navbar-fixed-text-color-hover: $nerd-gray-900;

$navbar-sticky-background-color: $white;
$navbar-sticky-text-color: rgba($nerd-gray-900, 0.2);
$navbar-sticky-text-color-hover: $nerd-gray-900;

$navbar-side-width: 250px;
$navbar-side-background-color: $white; //$living-coral;
$navbar-side-text-color: $nerd-gray-500;
$navbar-side-text-color-hover: $nerd-gray-500;
$navbar-side-border-color: $nerd-gray-500;
