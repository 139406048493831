// [ FONTS ]
@font-face {
  font-family: 'NotoKrL';
  font-style: normal;
  // font-weight: 100;
  src: url('fonts/notokr/notokr-light.eot');
  src: url('fonts/notokr/notokr-light.eot?#iefix') format('embedded-opentype'),
    url('fonts/notokr/notokr-light.woff2') format('woff2'),
    url('fonts/notokr/notokr-light.woff') format('woff');
}

@font-face {
  font-family: 'NotoKrR';
  font-style: normal;
  // font-weight: 300;
  src: url('fonts/notokr/notokr-regular.eot');
  src: url('fonts/notokr/notokr-regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/notokr/notokr-regular.woff2') format('woff2'),
    url('fonts/notokr/notokr-regular.woff') format('woff');
}

@font-face {
  font-family: 'NotoKrM';
  font-style: normal;
  // font-weight: 500;
  src: url('fonts/notokr/notokr-medium.eot');
  src: url('fonts/notokr/notokr-medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/notokr/notokr-medium.woff2') format('woff2'),
    url('fonts/notokr/notokr-medium.woff') format('woff');
}

@font-face {
  font-family: 'NotoKrB';
  font-style: normal;
  // font-weight: 700;
  src: url('fonts/notokr/notokr-bold.eot');
  src: url('fonts/notokr/notokr-bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/notokr/notokr-bold.woff2') format('woff2'),
    url('fonts/notokr/notokr-bold.woff') format('woff');
}

$font-family-complementary: 'NotoKrR', sans-serif !default;

$font-size-tiny: $font-size-base * 0.45 !default;
$font-size-small: $font-size-base * 0.85 !default;
$font-size-regular: $font-size-base !default;
$font-size-1d5: $font-size-base * 1.5 !default;
$font-size-m: $font-size-base * 2 !default;
$font-size-l: $font-size-base * 3.25 !default;
$font-size-xl: $font-size-base * 4.5 !default;
$font-size-2xl: $font-size-base * 6.5 !default;
$font-size-section-heading: 2.875rem !default;

$headings-letter-spacing: 0.05rem;
